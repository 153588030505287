import React, { Fragment, memo, ReactNode } from 'react';
import FpText from '../../../common/components/FpText/FpText';
import './DetailsItem.scss';

interface IDetailsItem {
  title: string;
  value: string;
  secondValue?: ReactNode;
  children?: React.ReactNode;
  infoPopup?: React.ReactNode;
  customStyle?: string;
  translateValue?: boolean
}

const DetailsItem = memo(({ children, customStyle, infoPopup, secondValue, title, value, translateValue = true }: IDetailsItem) => {
  if (value === undefined || value === '') {
    return <Fragment />;
  }

  const childrenContainer = children !== undefined ? <div className="child">{children}</div> : <Fragment />;
  const infoPopupComponent = infoPopup === undefined ? <Fragment /> : infoPopup;
  const customStyleToApply = customStyle ? ' ' + customStyle : '';

  return (
    <div className={'details-item' + customStyleToApply}>
      {childrenContainer}
      <div className="explanation">
        <FpText variant="body1">{title}</FpText>
        <div className="values-container">
          <p className="value">
            <span className={!translateValue ? 'do-not-translate' : ''}>{value} </span>
            <span>{infoPopupComponent}</span>
          </p>
          <p className="value">{secondValue} </p>
        </div>
      </div>
    </div>
  );
});

export default DetailsItem;
