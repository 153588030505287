import { DeviceStatus } from '@flowplan/flowplan-shared';
import { dateFormatYMD, timeFormatHM } from '@hsjakobsen/utilities/lib/date';
import { useEffect, useState } from 'react';
import { getDateObject } from '../Utility/time';

type TimeSinceLastSeen = {
    deviceStatus: DeviceStatus;
    lastSeen: string;
}

type LastSeenInfo = {
    valueOne: string;
    valueTwo: string;
}

let interval: NodeJS.Timeout;

export function useTimeSinceLastSeen({ deviceStatus, lastSeen }: TimeSinceLastSeen) {
    const [timeSinceLastSeen, setTimeSinceLastSeen] = useState<LastSeenInfo>({ valueOne: '', valueTwo: '' });
    useEffect(() => {
        if (deviceStatus === DeviceStatus.Pending) {
            setTimeSinceLastSeen({ valueOne: '-', valueTwo: '' });
            return;
        }

        const now = getDateObject(null);
        const lastSeenTime = getDateObject(lastSeen);
        const formatToUse = dateFormatYMD + ' - ' + timeFormatHM;
        const lastSeenFormatted = lastSeenTime.format(formatToUse);

        const today = now.clone().startOf('day');
        const yesterday = now.clone().subtract(1, 'days').startOf('day');

        const isYesterday = lastSeenTime.isSame(yesterday, 'd');
        const isToday = lastSeenTime.isSame(today, 'd');
        let dayInfoString = '';
        if (isToday) {
            dayInfoString = ' (Today)';
        }

        if (isYesterday) {
            dayInfoString = ' (Yesterday)';
        }

        const valueOne = lastSeenFormatted + dayInfoString;

        const hoursSinceAcivitity = now.diff(lastSeen, 'hours');
        const isInactive = hoursSinceAcivitity > 72;
        if (isInactive) {
            setTimeSinceLastSeen({ valueOne, valueTwo: '' });
            return;
        }

        setTimeSinceLastSeen({ valueOne, valueTwo: detectTimeSinceLastSeen(lastSeen) });

        interval = setInterval(() => {
            setTimeSinceLastSeen({ valueOne, valueTwo: detectTimeSinceLastSeen(lastSeen) });
        }, 1000);

        return () => {
            clearInterval(interval);
            setTimeSinceLastSeen({ valueOne: '-', valueTwo: '' });
        }
    }, [deviceStatus, lastSeen]);
    return timeSinceLastSeen;
}

function detectTimeSinceLastSeen(lastSeen: string) {
    const now = getDateObject(null);
    const lastSeenTime = getDateObject(lastSeen);

    const secondsSinceAcivitity = now.diff(lastSeenTime, 'seconds');
    const hours = Math.floor(secondsSinceAcivitity / 3600);
    const minutes = Math.floor((secondsSinceAcivitity - hours * 3600) / 60);
    const seconds = secondsSinceAcivitity - hours * 3600 - minutes * 60;

    const vSec = seconds > 9 ? seconds : `0${seconds}`;
    const vMin = minutes > 9 ? minutes : `0${minutes}`;
    const vHour = hours > 9 ? hours : `0${hours}`;

    return `${vHour}:${vMin}:${vSec}`
}
