import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { RouteDirectories } from '@flowplan/flowplan-shared/lib/api/routes.Directories';
import { IUser, RoleType } from '@flowplan/flowplan-shared/lib/users/users.database';
import { IUserRequestPackage } from '@flowplan/flowplan-shared/lib/users/users.requests';
import { IResponse, sortByKey } from '@hsjakobsen/utilities';
import { action, computed, thunk, thunkOn } from 'easy-peasy';
import { validateInputAdminAddUser } from '../../../Models/admin/admin.validation';
import { handlePostRequest, handlePutRequest } from '../../server-requests/server-requests';
import { IUsersModel } from '../types/users-types';
import { validateInputNotificationSettings, validateInputUsers } from './users-validation';

const usersModel: IUsersModel = {
  loadingUserData: false,
  users: [],
  deletedUsers: [],
  allUsers: [],
  adminUsers: [],
  numUsers: computed((state) => state.users.length),
  userInformation: undefined,

  retrieveUsers: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingUserData(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({
      route: Routes.users + payload.flowplanClientIdOverride,
    });
    if (requestResponse.success) {
      actions.setUsers(requestResponse.data);
    }
    actions.setLoadingUserData(false);
    actions.setAdminFromUsers();
    return requestResponse;
  }),

  getUserInformation: thunk(async (actions, payload, { getStoreActions }) => {
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.users });
    if (requestResponse.success) {
      actions.setUserInformation(requestResponse.data);
    }
    return requestResponse;
  }),

  setAdminFromUsers: action((state) => {
    state.adminUsers = state.users.filter((user) => user.role === RoleType.ADMIN);
  }),

  setLoadingUserData: action((state, payload) => {
    state.loadingUserData = payload;
  }),

  setUsers: action((state, payload) => {
    sortListByKey(payload);
    const usersToShow = payload.filter((userInList) => userInList.deletedAt === null);
    state.users = usersToShow;
    const deletedUsers = payload.filter((userInList) => userInList.deletedAt !== null);
    state.deletedUsers = deletedUsers;
    state.allUsers = payload;
  }),

  setUserInformation: action((state, payload) => {
    state.userInformation = payload;
  }),

  userRemove: thunk(async (actions, payload) => {
    const requestResponse = await handlePutRequest(
      { userId: payload },
      RouteDirectories.Users + Routes.usersDelete,
      true,
    );
    return requestResponse;
  }),

  handleUserData: thunk(async (actions, payload) => {
    actions.toggleNewUserInteraction(true);
    const validationResponse = validateInputUsers(payload.userInput);
    if (!validationResponse.success && validationResponse.message !== undefined) {
      actions.toggleNewUserInteraction(false);
      actions.setNewUserErrorMessage(validationResponse.message);
      return validationResponse.success;
    }

    const userRequestPackage = validationResponse.data as IUserRequestPackage;
    const userNotificationSettingsPackage = validateInputNotificationSettings({
      inputData: payload.notificationInput,
      userType: userRequestPackage.role,
    });
    let response: IResponse;
    if (payload.userInput.isUpdate) {
      const updateRequestPackage = userRequestPackage;
      delete updateRequestPackage.role;
      response = await handlePutRequest(updateRequestPackage, Routes.users, true);
    } else {
      delete userRequestPackage.id;
      response = await handlePostRequest(userRequestPackage, Routes.users, true);
    }
    if (response.success) {
      await actions.updateNotificationSetting({
        userId: response.data.userId,
        data: userNotificationSettingsPackage,
      });
    }
    actions.handleNewUserFeedback(response);
    return response.success;
  }),

  updateUser: thunk(async (actions, payload) => {
    actions.toggleNewUserInteraction(true);
    const validationResponse = validateInputUsers(payload.userInput);
    if (!validationResponse.success && validationResponse.message !== undefined) {
      actions.toggleNewUserInteraction(false);
      actions.setNewUserErrorMessage(validationResponse.message);
      return validationResponse.success;
    }

    const userRequestPackage = validationResponse.data as IUserRequestPackage;
    const userNotificationSettingsPackage = validateInputNotificationSettings({
      inputData: payload.notificationInput,
      userType: userRequestPackage.role,
    });
    const updateRequestPackage = userRequestPackage;
    delete updateRequestPackage.role;
    const response: IResponse = await handlePutRequest(updateRequestPackage, Routes.users, true);

    if (response.success) {
      await actions.updateNotificationSetting({
        userId: response.data.userId,
        data: userNotificationSettingsPackage,
      });
    }
    actions.handleNewUserFeedback(response);
    return response.success;
  }),

  adminAddUser: thunk(async (actions, payload) => {
    const newUserData = validateInputAdminAddUser(payload);
    if (!newUserData.success) {
      return newUserData;
    }

    const requestResponse = await handlePostRequest(newUserData.data, Routes.users, true);
    return requestResponse;
  }),

  handleNewUserFeedback: action((state, payload) => {
    state.newUserInteractionDisabled = false;
    if (!payload.success) {
      state.newUserErrorMessage = payload.message;

      if (payload.message !== '' && state.newUserErrorMessage === payload.message) {
        // document.forceUpdate();
        // console.log("force event area triggered");
      }

      if (!state.showNewUserModal) {
        alert('Error occured: ' + payload.message);
      }

      return;
    }
    state.newUserErrorMessage = '';
    state.showNewUserModal = false;
  }),

  resetUserData: action((state) => {
    state.users = [];
  }),

  // modal section
  modalUserData: undefined,
  newUserErrorMessage: '',
  newUserInteractionDisabled: false,
  showNewUserModal: false,

  setNewUserErrorMessage: action((state, payload) => {
    state.newUserErrorMessage = payload;
  }),

  toggleNewUserModal: action((state, payload) => {
    state.newUserErrorMessage = '';
    state.modalUserData = payload.userData;
    state.showNewUserModal = payload.showModal;
  }),
  toggleNewUserInteraction: action((state, payload) => {
    state.newUserInteractionDisabled = payload;
  }),

  // notification settings
  getNotificationSettings: thunk(async (actions, payload, { getStoreActions }) => {
    const route = Routes.notificationSettings + payload;
    return await getStoreActions().serverRequestsModel.get({ route: route });
  }),

  updateNotificationSetting: thunk(async (actions, payload) => {
    const route = Routes.notificationSettings + payload.userId;
    const data = payload.data;
    return await handlePutRequest(data, route, true);
  }),

  onActionsThatUpdateUsers: thunkOn(
    (actions) => [actions.userRemove, actions.handleUserData],
    async (actions, target, { getStoreState }) => {
      const flowplanClientId = getStoreState().adminDataModel.flowplanClientSelected;
      actions.retrieveUsers({ flowplanClientIdOverride: flowplanClientId });
      actions.getUserInformation();
    },
  ),
  onActionsThatUpdateUserInformation: thunkOn(
    (actions) => [
      actions.updateUser,
    ],
    async (actions, target) => {
      actions.getUserInformation();
    },
  ),
};

const sortListByKey = (list: IUser[]) => {
  sortByKey(list, 'username', true);
};

export default usersModel;
