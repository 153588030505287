type FindAndExtractData = {
    data: string;
    keyWord: string;
    pattern?: string;
}

const DEFAULT_PATTERN = '},"';

export const findAndExtractData = ({
    data,
    keyWord,
    pattern = DEFAULT_PATTERN
}: FindAndExtractData): string => {
    let result = '';
    if (data.includes(keyWord)) {
        const index = data.indexOf(keyWord);
        const validSubstring = data.substring(index);
        const [first] = validSubstring.split(pattern);
        result = first;

        if (pattern === DEFAULT_PATTERN) {
            result += '}'
        } else {
            result += ']'
        }
    }

    return result;
}

export type LogSuperDetails = {
    title: string;
    data: string[];
}

type ParseAndInsertData = {
    data: string;
    details: LogSuperDetails[];
    pattern?: string;
    titlePattern?: string;
}

export const parseAndInsertData = ({
    data,
    details,
    pattern = ',',
    titlePattern = '":{'
}: ParseAndInsertData): void => {
    if (data === '') {
        return;
    }
    const superDetailsItem: LogSuperDetails = {
        data: [],
        title: '',
    }

    const title = data.split(titlePattern);
    let dataToWorkWith = title[0];
    if (title.length > 1) {
        superDetailsItem.title = title[0].replace(/"/g, '');
        dataToWorkWith = title[1];
    }
    const dataToInsert = dataToWorkWith.split(pattern);
    for (const string of dataToInsert) {
        const prunedString = string.replace(/"/g, '').replace(/}/g, '').replace(/{/g, '').replace(/]/g, '').replace(/"/g, '');
        superDetailsItem.data.push(prunedString);
    }

    if (title.length > 2) {
        dataToWorkWith = title[2];
        const dataToInsert = dataToWorkWith.split(pattern);
        for (const string of dataToInsert) {
            const prunedString = string.replace(/"/g, '').replace(/}/g, '').replace(/{/g, '').replace(/]/g, '').replace(/"/g, '');
            superDetailsItem.data.push(prunedString);
        }
    }

    details.push(superDetailsItem);
}