import { getSerialNumberToShow, notAvailable } from '@flowplan/flowplan-shared';
import { dateFormatYMD, timeFormatHMS } from '@hsjakobsen/utilities/lib/date';
import { Fragment, memo, useCallback, useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../Store';
import { getDateObject } from '../../../../Utility/time';
import FpDivider from '../../../common/components/FpDivider/FpDivider';
import FpGrid from '../../../common/components/FpGrid/FpGrid';
import FpGridItem from '../../../common/components/FpGrid/FpGridItem';
import FpText from '../../../common/components/FpText/FpText';
import DetailsItem from '../Common/DetailsItem';

const DeviceConfigurationComponent = memo((): JSX.Element => {
  const selectedInstallation = useStoreState((state) => state.graphSettingsModel.selectedInstallation);
  const flowplanClientSelected = useStoreState((state) => state.adminDataModel.flowplanClientSelected);

  const connectionData = useStoreState((state) => state.deviceConfigModel.connectionData);
  const deviceConfiguration = useStoreState((state) => state.deviceConfigModel.deviceConfiguration);

  const retrieveConnectionData = useStoreActions((actions) => actions.deviceConfigModel.retrieveConnectionData);
  const retrieveDeviceConfiguration = useStoreActions(
    (actions) => actions.deviceConfigModel.retrieveDeviceConfiguration,
  );

  const retrieveData = useCallback(async () => {
    await retrieveDeviceConfiguration(selectedInstallation.infoBasic.id);

    await retrieveConnectionData({
      deviceId: selectedInstallation.infoBasic.id,
      flowplanClientId: flowplanClientSelected,
    });
  }, [selectedInstallation, flowplanClientSelected, retrieveConnectionData, retrieveDeviceConfiguration]);

  useEffect(() => {
    retrieveData();
  }, [retrieveData]);

  if (deviceConfiguration === undefined || deviceConfiguration.id === undefined) {
    return <FpText variant="body1"> No configuration information found</FpText>;
  }

  const flowEventPauseUnit = selectedInstallation.infoDeviceMetrics.firmware >= '2024.05' ? 'milliseconds' : 'seconds';

  const infoForSimCard = deviceConfiguration.ccid === '-1' ? notAvailable : deviceConfiguration.ccid;

  const gridLayout = { xs: 12, sm: 6, md: 4, lg: 4 };

  return (
    <Fragment>
      <FpText variant="h5">Beam information</FpText>
      <FpGrid>
        <FpGridItem {...gridLayout}>
          <DetailsItem title="Device name:" value={selectedInstallation.infoBasic.name} />
        </FpGridItem>

        <FpGridItem {...gridLayout}>
          <DetailsItem
            title="Beam serial:"
            value={getSerialNumberToShow(selectedInstallation.infoBasic.flowplanDeviceId)}
          />
        </FpGridItem>

        <FpGridItem {...gridLayout}>
          <DetailsItem
            title="Configuration received"
            value={'' + getDateObject(deviceConfiguration.receivedTime).format(dateFormatYMD + ' ' + timeFormatHMS)}
          />
        </FpGridItem>
      </FpGrid>

      <FpDivider />
      <FpText variant="h5">Configuration</FpText>
      <FpGrid>
        <FpGridItem {...gridLayout}>
          <DetailsItem title="Hardware:" value={selectedInstallation.infoDeviceMetrics.hardware} />
        </FpGridItem>

        <FpGridItem {...gridLayout}>
          <DetailsItem title="Firmware:" value={selectedInstallation.infoDeviceMetrics.firmware} />
        </FpGridItem>

        <FpGridItem {...gridLayout}>
          <DetailsItem
            title="Battery installation date:"
            value={selectedInstallation.infoDeviceMetrics.batteryInstallationDate}
          />
        </FpGridItem>
        <FpGridItem {...gridLayout}>
          <DetailsItem
            title="Heartbeat interval"
            value={'Once every ' + deviceConfiguration.heartbeatInterval / 60 + ' hours'}
          />
        </FpGridItem>
        <FpGridItem {...gridLayout}>
          <DetailsItem
            title="Payloads collected before sending"
            value={deviceConfiguration.flowEventsDepth + ' events'}
          />
        </FpGridItem>
        <FpGridItem {...gridLayout}>
          <DetailsItem title="Leak detection time" value={deviceConfiguration.flowEventAutoFlush + ' minutes'} />
        </FpGridItem>
        <FpGridItem {...gridLayout}>
          <DetailsItem
            title="Time after last FlowEvent till autosend"
            value={deviceConfiguration.flowEventsAggregrationTime + ' minutes'}
          />
        </FpGridItem>
        <FpGridItem {...gridLayout}>
          <DetailsItem
            title="Grouped Event threshold"
            value={deviceConfiguration.groupedFlowEventThreshold + ' pulses'}
          />
        </FpGridItem>
        <FpGridItem {...gridLayout}>
          <DetailsItem title="Small events limit" value={deviceConfiguration.smallFlowEventThreshold + ' pulses'} />
        </FpGridItem>

        <FpGridItem {...gridLayout}>
          <DetailsItem title="Max uplinks per hour" value={'' + deviceConfiguration.uplinksLimit} />
        </FpGridItem>
        <FpGridItem {...gridLayout}>
          <DetailsItem
            title="Feature set"
            value={deviceConfiguration.featureSet === 1 ? 'Continous mode' : 'Normal mode'}
          />
        </FpGridItem>
        <FpGridItem {...gridLayout}>
          <DetailsItem
            title="Pause between flowEvents"
            value={deviceConfiguration.flowEventDetectionTimeout + ' ' + flowEventPauseUnit}
          />
        </FpGridItem>
        <FpGridItem {...gridLayout}>
          <DetailsItem title="Uplink max size" value={deviceConfiguration.uplinkMaxSize + ' bytes'} />
        </FpGridItem>
        <FpGridItem {...gridLayout}>
          <DetailsItem title="SIM ICCID" value={'' + infoForSimCard} />
        </FpGridItem>
        <FpGridItem {...gridLayout}>
          <DetailsItem title="APN" value={selectedInstallation.infoDeviceMetrics.apn} />
        </FpGridItem>
      </FpGrid>

      <FpDivider />
      <FpText variant="h5">Stats</FpText>
      <FpGrid>
        <FpGridItem {...gridLayout}>
          <DetailsItem title="Connections - total" value={'' + connectionData.connectionsTotal} />
        </FpGridItem>
        <FpGridItem {...gridLayout}>
          <DetailsItem title="Transmissions - total" value={'' + connectionData.transmissionsTotal} />
        </FpGridItem>
        <FpGridItem {...gridLayout}>
          <DetailsItem title="Errors - total" value={'' + connectionData.errorsTotal} />
        </FpGridItem>
        <FpGridItem {...gridLayout}>
          <DetailsItem title="Connections - since serial change" value={'' + connectionData.connectionsSerialChange} />
        </FpGridItem>
        <FpGridItem {...gridLayout}>
          <DetailsItem
            title="Transmissions - since serial change"
            value={'' + connectionData.transmissionsSerialChange}
          />
        </FpGridItem>
        <FpGridItem {...gridLayout}>
          <DetailsItem title="Errors - since serial change" value={'' + connectionData.errorsSerialChange} />
        </FpGridItem>
      </FpGrid>
    </Fragment>
  );
});

export default DeviceConfigurationComponent;
