import { IAdminOverviewItem } from '@flowplan/flowplan-shared/lib/admin/admin.overview';
import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { CompanyTag, IDeviceView } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { Action, action, computed, Computed, Thunk, thunk } from 'easy-peasy';
import { ItemsPerPage } from '../../../common/constsFrontend';
import { IStoreModel } from '../../../Models';
import { getDateObject } from '../../../Utility/time';
import { handlePostRequest } from '../../server-requests/server-requests';
import { IResponse } from '../../server-requests/server-requests-model';
import { FaultyDeviceOverviewMarkerStatus } from '../assets/faulty-devices-icons';
import { faultyDeviceStatus } from './faulty-devices-utils';

export const faultyDevicesPerPage = ItemsPerPage.OneThousand;

interface IFaultyDeviceRequest {
  batteryTypes: number[] | string[];
  flowmeterIds: number[] | string[];
  excludeFlowplanClients: boolean;
  firmwareVersions: string[] | number[];
  flowplanClients: number[] | string[];
  apnSelection: string[];
  installationDate: string;
  lastSeenHours: string;
  onlyLowUsage: boolean;
  newGenerationSetting: number;
  showInactive: boolean;
  excludedTags: number[];
}

export interface IFaultyDeviceItem extends IAdminOverviewItem {
  statusCode: FaultyDeviceOverviewMarkerStatus;
}
export interface IFaultyDevicesModel {
  numFaultyDevices: Computed<IFaultyDevicesModel, number>;

  numOffline: number;
  numOnline: number;
  numPending: number;
  numPercentageOffline: string;

  faultyDevices: IFaultyDeviceItem[];
  getFaultyDeviceOverview: Thunk<IFaultyDevicesModel, IFaultyDeviceRequest, void, IStoreModel>;
  setFaultyDevices: Action<IFaultyDevicesModel, IFaultyDeviceItem[]>;

  loadingFaultyDevices: boolean;
  setLoadingFaultyDevices: Action<IFaultyDevicesModel, boolean>;

  getFaultyDevicesSelection: Thunk<IFaultyDevicesModel, number[]>;
  loadingFaultyDeviceSelection: boolean;
  setLoadingFaultyDeviceSelection: Action<IFaultyDevicesModel, boolean>;
  faultyDeviceSelectionInstallations: IDeviceView[];
  setFaultyDeviceSelectionInstallations: Action<IFaultyDevicesModel, IDeviceView[]>;

  getGlobalTags: Thunk<IFaultyDevicesModel, void, void, IStoreModel>;
  globalTags: CompanyTag[];
  setGlobalTags: Action<IFaultyDevicesModel, CompanyTag[]>;
}

const faultyDevicesModel: IFaultyDevicesModel = {
  numFaultyDevices: computed((state) => state.faultyDevices.length),

  numOffline: 0,
  numOnline: 0,
  numPending: 0,
  numPercentageOffline: '0',

  loadingFaultyDevices: false,
  faultyDevices: [],

  setFaultyDevices: action((state, payload) => {
    state.faultyDevices = payload;

    if (payload.length === 0) {
      state.numOnline = 0;
      state.numPending = 0;
      state.numOffline = 0;
      state.numPercentageOffline = "0";
      return;
    }

    const numOnline = payload.filter((item) => item.statusCode === FaultyDeviceOverviewMarkerStatus.online).length;
    const numPending = payload.filter((item) => item.statusCode === FaultyDeviceOverviewMarkerStatus.pending).length;
    const numOffline: number = payload.length - numOnline - numPending;
    const numPercentageOffline = ((numOffline / payload.length) * 100).toFixed(1);
    state.numOnline = numOnline;
    state.numPending = numPending;
    state.numOffline = numOffline;
    state.numPercentageOffline = numPercentageOffline;
  }),

  setLoadingFaultyDevices: action((state, payload) => {
    state.loadingFaultyDevices = payload;
  }),

  getFaultyDeviceOverview: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingFaultyDevices(true);

    const params: IFaultyDeviceRequest = {
      ...payload
    };

    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.faultyDevices, params });
    if (requestResponse.success) {
      const faultyDevicesReceived = requestResponse.data as IAdminOverviewItem[];

      const currentTime = getDateObject(null);
      const overviewItems: IFaultyDeviceItem[] = [];
      faultyDevicesReceived.forEach((item) => {
        const locationCheck = item.location || 'N/A';
        const areaCheck = item.area || 'N/A';
        const lastSeenCheck = item.lastSeen || 'N/A';
        const statusCode = faultyDeviceStatus({ currentTime, lastSeen: lastSeenCheck });
        const itemToReturn: IFaultyDeviceItem = {
          ...item,
          area: areaCheck,
          lastSeen: lastSeenCheck,
          location: locationCheck,
          statusCode,
        };
        overviewItems.push(itemToReturn);
      });

      actions.setFaultyDevices(overviewItems);
    }
    actions.setLoadingFaultyDevices(false);
  }),

  getFaultyDevicesSelection: thunk(async (actions, payload) => {
    actions.setLoadingFaultyDeviceSelection(true);
    const requestResponse = await handlePostRequest(payload, '/faulty-devices/selection', true);
    if (requestResponse.success) {
      const faultyDeviceSelection = requestResponse.data as IDeviceView[];
      actions.setFaultyDeviceSelectionInstallations(faultyDeviceSelection);
    } else {
      actions.setFaultyDeviceSelectionInstallations([]);
    }

    actions.setLoadingFaultyDeviceSelection(false);
  }),
  loadingFaultyDeviceSelection: false,
  setLoadingFaultyDeviceSelection: action((state, payload) => {
    state.loadingFaultyDeviceSelection = payload;
  }),
  faultyDeviceSelectionInstallations: [],
  setFaultyDeviceSelectionInstallations: action((state, payload) => {
    state.faultyDeviceSelectionInstallations = payload;
  }),
  globalTags: [],
  setGlobalTags: action((state, payload) => {
    state.globalTags = payload;
  }),



  getGlobalTags: thunk(async (actions, payload, { getStoreActions }) => {
    const requestResponse: IResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.globalTags });
    if (requestResponse.success) {
      actions.setGlobalTags(requestResponse.data as CompanyTag[]);
    }

    return requestResponse;
  }),
};

export default faultyDevicesModel;
